// ==============================
// Custom style
// 自定义样式
// ==============================
@import url('https://fonts.googleapis.com/css?family=Fira+Mono:400,700&display=swap&subset=latin-ext');
$code-font-family: Fira Mono, Source Code Pro, Menlo, Consolas, Monaco, monospace;
$global-font-color: #b48922 !default;

// Color of the link
$global-link-color: #04350c !default;
$global-link-color-dark: #07af3f !default;

// Color of the hover link
$global-link-hover-color: #065b7a !default;
$global-link-hover-color-dark: rgb(19, 209, 223) !default;